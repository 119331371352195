import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { Float, useSize, useInternal } from "uikit";
import { isEqual } from "lodash";
import { useDebouncedCallback } from "use-debounce";

import {
	emptySchema,
	IOrderPoint,
	Schema,
	useOrderForm,
} from "../../../../../../redux/constants/OrdersPage/order";
import PointsTable, {
	PointsTableMemo,
} from "../../../../../../components/PointsTable/PointsTable";
import LegacyTableColumn from "../../../../../../types/TableColumn";
import {
	Divider,
	StyledColumn,
	StyledGrid,
	StyledGridItem,
	StyledRow,
} from "../../../../../../components/common";
import { useOrderModalContext } from "../../../context";

import { useAddPointRow } from "./hooks/useAddPointRow";
import { Tab, tabStateAreEqual } from "./types/Tab";
import Header from "./components/Header";
import OrderRideInformation from "./components/OrderRideInformation";
import { PersonalInformation } from "./components/PersonalInformation";
import OrderInformation from "./components/OrderInformation";
import { DiscountPlane } from "./components/DiscountPlan";
import { AgentReward } from "./components/AgentReward";
import { Summary } from "./components/Summary";
import Car from "./components/Car";

const OrderForm: React.FC<OrderForm.Props> = (props) => {
	const {
		tab,
		disabled = false,
		onFormChange,
		onEditPoint,
		onOpenPointModal,
		setTabAccept,
		tableData,
		onRowClick,
		activePoint = 0,
		onChangeActivePoint,
		onRemovePoint,
		onModifyPoint,
		onSavePoints,
		tableHeaderHeight = 26,
		tableRowHeight = 32,
		tableSchema,
		onColumnResize,
		rightPart,
		footer,
		setPermissionBounds,
		onInsert,
		heightModal = "100%",
	} = props;

	const { companyID, points, taxiServiceId, customerId, searchTypes } =
		useOrderModalContext();
	const formTab = useOrderForm(tab.form);
	const refModal = useRef<HTMLDivElement | null>(null);
	const ref = useRef<HTMLDivElement | null>(null);
	const sizeModal = useSize(refModal.current);
	const size = useSize(ref.current);

	const form = useMemo(() => formTab, [formTab]);
	const [save, setSave] = useInternal<boolean>(false);
	const { setBaseRow, addTableRows } = useAddPointRow({
		taxiServiceId,
		companyID,
		coordinates: tab.state.defaultCity.coordinates,
		settlement: tab.state.defaultCity.settlement,
	});

	const data = useMemo(
		() => addTableRows(tableData),
		[addTableRows, tableData],
	);

	const hasPoints = useMemo(() => {
		const hashData = points?.map((item) => {
			const dataHash = {
				...(item?.coordinates || {}),
				entrance: item?.entrance || "",
				name: item?.name || "",
				title: item?.title || "",
			};
			const payload = JSON.stringify(dataHash);
			return payload;
		});

		return hashData?.flat().join("|") || "";
	}, [points]);

	useMemo(() => {
		if (disabled) return;
		if (onSavePoints && save && hasPoints) onSavePoints(points);
		setSave(false);
	}, [disabled, onSavePoints, save, hasPoints, points, setSave]);

	useMemo(() => {
		if (setPermissionBounds) setPermissionBounds(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [points]);

	useEffect(() => {
		const subscription = form.watch((formState) => {
			if (disabled) return;
			Object.keys(emptySchema).forEach((k) => {
				// eslint-disable-next-line no-param-reassign
				if (formState[k] == null) formState[k] = emptySchema[k];
			});
			onFormChange?.(formState as Schema);
		});

		return () => {
			subscription.unsubscribe();
		};
	}, [disabled, form, form.watch, onFormChange]);

	const handlePointSave = useCallback(() => {
		if (disabled) return;
		form.setValue("orderPointSave", true);
		form.setValue("orderSave", false);
	}, [disabled, form]);

	const moveDown = useCallback(() => {
		if (disabled) return;
		const { points } = form.getValues();
		const swapPoint = (activePoint + 1) % points.length;
		const newPoints: any[] = Array.from(points);

		const temp = newPoints[activePoint];
		newPoints[activePoint] = newPoints[swapPoint];
		newPoints[swapPoint] = temp;

		form.setValue("points", newPoints);
		onChangeActivePoint(swapPoint);
		handlePointSave();
	}, [disabled, form, activePoint, onChangeActivePoint, handlePointSave]);

	const moveUp = useCallback(() => {
		if (disabled) return;
		const { points } = form.getValues();

		let swapPoint = activePoint - 1;
		if (swapPoint < 0) swapPoint += points.length;

		const newPoints = Array.from(points);
		const temp = newPoints[activePoint];
		newPoints[activePoint] = newPoints[swapPoint];
		newPoints[swapPoint] = temp;

		form.setValue("points", newPoints as any[]);
		onChangeActivePoint(swapPoint);
		handlePointSave();
	}, [disabled, form, activePoint, onChangeActivePoint, handlePointSave]);

	/** height the table of order point  */
	const height = useMemo(() => {
		const heightRef = Math.ceil(size.height);
		const heightModalRef = Math.ceil(sizeModal.height);
		const rowHeight = 564 + 38;

		const addRowHeight = Math.ceil(
			(heightModalRef - rowHeight) / tableRowHeight,
		);

		const addMinRowHeight =
			addRowHeight > 0 ? data.length + addRowHeight : data.length;
		const addMinHeight = addMinRowHeight < 2 ? 18 : 38;
		const min =
			tableRowHeight * addMinRowHeight +
			tableRowHeight +
			tableHeaderHeight +
			addMinHeight;

		const addMaxRowHeight = addRowHeight <= 0 ? 4 : 4 + addRowHeight;
		const addMaxHeight = addMaxRowHeight < 3 ? 0 : 18;
		const max =
			tableRowHeight * addMaxRowHeight +
			tableRowHeight +
			addMaxHeight +
			tableHeaderHeight -
			heightRef;

		const maxMore = max >= 202 ? 202 : max;

		return min >= maxMore
			? `${maxMore.toFixed(2)}px`
			: `${min.toFixed(2)}px`;
	}, [data, size, tableRowHeight, tableHeaderHeight, sizeModal]);

	const widthOrderInformation = useMemo(() => {
		const oneRow = 564 + 38 + 15;

		const sizeModalHeight = Math.ceil(sizeModal.height);
		const sizeHeight = Number(size.height.toFixed(2));

		if (
			sizeModalHeight > oneRow &&
			sizeHeight >= 38 &&
			sizeHeight < 60.99
		) {
			return "100%";
		}

		const towRow = 564 + 61 + 15;
		if (
			sizeModalHeight > towRow &&
			sizeHeight >= 61 &&
			sizeHeight < 81.99
		) {
			return "100%";
		}
		const threeRow = 564 + 82 + 15;
		if (sizeModalHeight > threeRow && sizeHeight >= 82) {
			return "100%";
		}

		return "calc(100% - 38px)";
	}, [size, sizeModal]);
	//  -----height the table of order point end----

	const setData = useCallback(
		(arr: PointsTable.Props["data"]) => {
			if (disabled) return;
			const newPoints = Array.from(arr);
			form.setValue("points", newPoints);
			form.setValue("processedByTaximeter", false);
			if (onModifyPoint) onModifyPoint(newPoints);
			setSave(true);
		},
		[disabled, form, onModifyPoint, setSave],
	);

	const onDelete = useCallback(
		(index: number) => {
			if (disabled) return;
			const filteredPoints = points?.filter((_, i) => i !== index);
			form.setValue("points", filteredPoints);
			form.setValue("processedByTaximeter", false);
			if (onRemovePoint) onRemovePoint(filteredPoints);
			setSave(true);
		},
		[form, onRemovePoint, points, setSave, disabled],
	);

	const onEdit = useCallback(() => {
		if (activePoint !== -1 && points.length) {
			if (onEditPoint) onEditPoint();
		}
	}, [activePoint, onEditPoint, points.length]);

	const setActivePoint = useDebouncedCallback((num: number) => {
		if (onRowClick) onRowClick(num);
	}, 110);

	const onInsertPoint = useCallback(() => {
		if (onInsert) onInsert();
	}, [onInsert]);

	const onAddRow = useCallback(
		(num: number) => {
			const activeRow = num > 2 ? num : 2;
			setBaseRow(activeRow);
			setActivePoint(activeRow - 1);
		},
		[setBaseRow, setActivePoint],
	);

	return (
		<Float.Container
			id={"orderModal"}
			style={{ height: "100%", width: "100%" }}
		>
			<StyledGrid
				ref={refModal}
				areas={`'header header'
					'left right'
					'footer footer'`}
				columns="65% 35%"
				rows="clamp(27px, 3.7vh, 35px) 1fr clamp(37px, 5.6vh, 50px)"
				position="relative"
				w="100%"
				h={heightModal}
				alignItems="start"
				justify="space-between"
				bgC="#ffffff"
				br="5px"
			>
				<StyledGridItem
					area="header"
					w="100%"
					justifySelf="baseline"
					alignSelf="center"
				>
					<Header disabled={disabled} tab={tab} form={form} />
				</StyledGridItem>

				<StyledGridItem area="left" column="1fr" row="1fr">
					<StyledColumn w="100%" h="100%" justify="start">
						<PersonalInformation
							setTabAccept={() => {
								setTabAccept?.();
							}}
							tab={tab}
							form={form}
							disabled={disabled}
						/>

						<Divider side="top" />
						<StyledColumn flex={{ flex: "1 0 0" }}>
							<PointsTableMemo
								data={data}
								setData={setData}
								customerId={customerId}
								disabled={disabled}
								whereCalled="orderModal"
								onAddBtnClick={onOpenPointModal}
								height={height}
								navWrap="nowrap"
								onEdit={onEdit}
								onDelete={onDelete}
								onRowClick={onRowClick}
								activeRow={activePoint}
								error={!tab.validator.points}
								onUpActivePoint={moveUp}
								onDownActivePoint={moveDown}
								// Work with the height and width table row
								tableHeaderHeight={tableHeaderHeight}
								tableRowHeight={tableRowHeight}
								tableSchema={tableSchema}
								onColumnResize={onColumnResize}
								onAddRow={onAddRow}
								onInsertPoint={onInsertPoint}
								//
								searchTypes={searchTypes}
								taxiServiceId={taxiServiceId}
							/>
						</StyledColumn>

						<StyledRow ref={ref}>
							<OrderInformation
								disabled={disabled}
								tab={tab}
								form={form}
								width={widthOrderInformation}
							/>
						</StyledRow>

						<Divider side="top" />
						<OrderRideInformation
							disabled={disabled}
							tab={tab}
							form={form}
						/>
						<Divider side="top" />
						<StyledColumn
							h={{ min: "40px", max: "62px" }}
							justify="center"
							p="2px 0"
							gap="2px"
						>
							<DiscountPlane
								disabled={disabled}
								tab={tab}
								form={form}
							/>
							<AgentReward
								disabled={disabled}
								tab={tab}
								form={form}
							/>
						</StyledColumn>
						<Divider side="top" />
						<Summary disabled={disabled} tab={tab} form={form} />
						<Car disabled={disabled} tab={tab} form={form} />
					</StyledColumn>
				</StyledGridItem>

				<StyledGridItem area="right">
					<StyledRow w="100%" h="100%">
						<Divider />
						{rightPart}
					</StyledRow>
				</StyledGridItem>

				<StyledGridItem area="footer" alignSelf="center">
					<StyledRow w="100%" h="100%">
						{footer}
					</StyledRow>
				</StyledGridItem>
			</StyledGrid>
		</Float.Container>
	);
};

declare namespace OrderForm {
	interface Props {
		heightModal?: string;
		disabled?: boolean;
		tab: Tab;
		tableData: IOrderPoint[];
		rightPart?: React.ReactNode;
		footer?: React.ReactNode;

		activePoint: number;
		onChangeActivePoint: (index: number) => void;

		setTabAccept?: () => void;
		onEditPoint?: () => void;
		onInsert?: () => void;
		onRowClick?: (index: number) => void;
		onOpenPointModal?: () => void;
		onFormChange?: (formData: Schema) => void;

		onSavePoints?: (points: IOrderPoint[]) => void;
		onRemovePoint?: (points: IOrderPoint[]) => void;
		onModifyPoint?: (points: IOrderPoint[]) => void;
		tableHeaderHeight?: number;
		tableRowHeight?: number;
		tableSchema?: LegacyTableColumn<string>[];
		onColumnResize?: (width?: number, columnKey?: string | number) => void;
		setPermissionBounds?: React.Dispatch<React.SetStateAction<boolean>>;
	}
}

export default React.memo(OrderForm, (prev, next) => {
	if (prev.tab.uid !== next.tab.uid) return false;
	const stateEqual = tabStateAreEqual(prev.tab.state, next.tab.state);
	if (!stateEqual) return false;
	if (prev.rightPart !== next.rightPart) return false;
	if (prev.tableRowHeight !== next.tableRowHeight) return false;
	if (prev.activePoint !== next.activePoint) return false;
	if (prev.disabled !== next.disabled) return false;
	if (prev.tableData !== next.tableData) return false;
	if (prev.tab?.form?.price !== next.tab?.form?.price) return false;
	if (prev.tableHeaderHeight !== next.tableHeaderHeight) return false;
	if (prev.tableSchema !== next.tableSchema) return false;

	if (prev.onColumnResize !== next.onColumnResize) return false;
	if (prev.onFormChange !== next.onFormChange) return false;
	if (prev.onEditPoint !== next.onEditPoint) return false;
	if (prev.onOpenPointModal !== next.onOpenPointModal) return false;
	if (prev.onRowClick !== next.onRowClick) return false;
	if (prev.onChangeActivePoint !== next.onChangeActivePoint) return false;

	if (prev.onSavePoints !== next.onSavePoints) return false;
	if (prev.onRemovePoint !== next.onRemovePoint) return false;
	if (prev.onModifyPoint !== next.onModifyPoint) return false;

	if (prev.setPermissionBounds !== next.setPermissionBounds) return false;

	if (!isEqual(prev.tab?.form, next.tab?.form)) return false;
	if (!next.tab?.form.points.length) return true;
	if (!isEqual(prev.tab?.form.points, next.tab?.form.points)) return false;
	return true;
});
