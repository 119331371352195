import React, {
	useCallback,
	useEffect,
	useLayoutEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { Map as MapBase, useChanged } from "uikit";
import { Map as LeafletMap } from "leaflet";
import Control from "react-leaflet-custom-control";
import { assign, clone } from "lodash";

import { useTypedSelector } from "../../../redux/store";
import useMapSearch from "../../../hooks/useMapSearch";
import addressToString from "../../../utils/addressToString";
import MapService from "../../../services/Map";
import useObjectEditor from "../../../hooks/useObjectEditor";
import useCurrentTaxiServices from "../../../hooks/useCurrentTaxiService";
import ExecutorLocations from "../../../services/ExecutorLocations";
import useTaxiServiceIdsDecoder from "../../../hooks/useTaxiServiceIdsDecoder";
import { ExecutorStatus } from "../../../services/Executor";
import useModelSubscribe from "../../../hooks/useModelSubscribe2";
import { Orders } from "../../../redux/reducers/Orders";
import MapExecutorLocations from "../../MapExecutorLocations";

import useMapActions from "./hooks/useMapActions";
import {
	Header,
	Root,
	ControlButtons,
	ShowButtons,
	PriceZones,
	Sectors,
	Parkings,
	Route,
	MapObject,
} from "./components";

function isMapObject(
	object: MapService.Search.Object | ExecutorLocations.Model,
): object is MapService.Search.Object {
	return "geometry" in object;
}

const headerValueStatusToExecutorStatus = {
	ownOrder: null,
	free: ExecutorStatus.AVAILABLE,
	busy: ExecutorStatus.BUSY,
	lunch: ExecutorStatus.DINNER,
	home: ExecutorStatus.HOME,
	onOrder: ExecutorStatus.ON_ORDER,
	closedByOffice: ExecutorStatus.CLOSED,
};

const Map: React.FC<Map.Props> = () => {
	const {
		setMapWidgetOrderTabExecutorFilters,
		setMapWidgetCenter,
		setMapWidgetZoom,
		setALLBtnToggles,
	} = useMapActions();

	const mapRef = useRef<LeafletMap | null>(null);
	const mapObjectRef = useRef<MapObject.Controller | null>(null);
	const routeRef = useRef<Route.Controller | null>(null);
	const executorsRef = useRef<MapExecutorLocations.Controller | null>(null);

	const language = useTypedSelector((state) => state.session.language);
	const activeOrderTab = useTypedSelector(
		(state) => state.ordersPageReducer.ordersType,
	);
	const mapWidgetDefaultExecutorsFilters = useTypedSelector(
		(state) => state.orders.map.filter.persistent.executors.defaultFilters,
	);
	const mapWidgetExecutorFilters = useTypedSelector(
		(state) =>
			state.orders.map.filter.persistent.executors.filtersByOrderTab[
				activeOrderTab
			],
	);
	const mapCenter = useTypedSelector(
		(state) => state.orders.map.filter.inconstant.focus.center,
	);
	const mapZoom = useTypedSelector(
		(state) => state.orders.map.filter.inconstant.focus.zoom,
	);
	const btnOnMap = useTypedSelector(
		(state) => state.orders.map.toggles.btnOnMap,
	);
	const activeOrder = useTypedSelector(
		(state) => state.ordersPageReducer.activeOrder,
	);
	const activePoint = useTypedSelector(
		(state) => state.ordersPageReducer.activePoint,
	);

	const defaultMapCenter = useMemo(
		() => ({ lat: 50.455002, lng: 30.511284 }),
		[],
	);

	const [object, setObject] = useState<MapService.Search.Object | null>(null);
	const [searchValue, setSearchValue] = useState<
		Pick<Header.Value, "search" | "searchType">
	>({
		search: "",
		searchType: "address",
	});
	const [showValue, setShowValue] =
		useState<Orders.Map.BtnOnMapToggles>(btnOnMap);

	const headerValue = useMemo<Header.Value>(
		() => ({
			...searchValue,
			...mapWidgetExecutorFilters,
		}),
		[mapWidgetExecutorFilters, searchValue],
	);

	const setHeaderValue = useCallback(
		({ search, searchType, ...executorFilters }: Header.Value) => {
			setSearchValue({
				search,
				searchType,
			});
			setMapWidgetOrderTabExecutorFilters(
				activeOrderTab,
				executorFilters,
			);
		},
		[activeOrderTab, setMapWidgetOrderTabExecutorFilters],
	);

	const headerValueEditor = useObjectEditor(headerValue, setHeaderValue);

	const headerValueSearch = headerValueEditor.useGetter("search");
	const setHeaderValueSearch = headerValueEditor.useSetter("search");

	const currentTaxiService = useCurrentTaxiServices();

	useChanged(
		() => {
			if (!mapRef.current || "id" in activeOrder) return;

			mapRef.current.setView(mapCenter ?? defaultMapCenter);
		},
		mapCenter,
		true,
	);

	const mapSearchResult = useMapSearch({
		query: headerValue.searchType === "address" ? headerValue.search : "",
		country: "ua",
		searchType: [],
		language,
		near: {
			point: mapRef.current?.getCenter() ?? mapCenter ?? defaultMapCenter,
			zoom: mapRef.current?.getZoom() ?? undefined,
		},
	});

	const decodeTaxiServiceIds = useTaxiServiceIdsDecoder();

	const executorsFilters = useMemo<ExecutorLocations.SubscribeOptions>(() => {
		if (headerValue.onActiveOrder) {
			return {
				revealExecutor: true,

				orderIds: "id" in activeOrder ? [activeOrder.id] : [],
			};
		}

		const open = headerValue.shifts.includes("open");
		const closed = headerValue.shifts.includes("closed");

		return {
			revealExecutor: true,
			taxiServiceIds: decodeTaxiServiceIds(
				headerValue.companyIds,
				headerValue.taxiServiceIds,
			),

			// executorDebt: headerValue.statuses.includes("autoClose"),

			executorWorkingStatus:
				(open && closed) || (!open && !closed) ? undefined : open,

			executorStatuses: headerValue.statuses
				.map((status) => headerValueStatusToExecutorStatus[status])
				.filter(Boolean) as ExecutorStatus[],
		};
	}, [
		activeOrder,
		decodeTaxiServiceIds,
		headerValue.companyIds,
		headerValue.onActiveOrder,
		headerValue.shifts,
		headerValue.statuses,
		headerValue.taxiServiceIds,
	]);

	const executorLocationsData = useModelSubscribe(
		executorsFilters,
		ExecutorLocations,
	);

	const searchOptions = useMemo<
		Header.SearchOption<
			MapService.Search.Object | ExecutorLocations.Model
		>[]
	>(() => {
		if (headerValue.searchType === "address") {
			return mapSearchResult.loading
				? []
				: mapSearchResult.objects.map((object) => {
						const address = {
							country: object.country ?? "",
							countryCode: object.countryCode ?? "",
							street: object.street ?? "",
							streetType: object.streetType ?? "",
							settlement: object.settlement ?? "",
							settlementType: object.settlementType ?? "",
							district: object.district ?? "",
							region: object.region ?? "",
							house: object.number ?? "",
						};

						return {
							key: object.id,
							label: [addressToString(address), object.name]
								.filter(Boolean)
								.join(", "),
							value: object,
						};
				  });
		}

		if (headerValue.search === "") {
			return [];
		}

		return executorLocationsData.models
			.filter((executorLocation) =>
				executorLocation.executor.alias
					.toLowerCase()
					.includes(headerValue.search.toLowerCase()),
			)
			.map((executorLocation) => ({
				key: executorLocation.id,
				label: executorLocation.executor.alias,
				value: executorLocation,
			}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		executorLocationsData.models,
		headerValue.search,
		headerValue.searchType,
		// eslint-disable-next-line react-hooks/exhaustive-deps
		!mapSearchResult.loading && mapSearchResult.objects,
	]);

	const headerOnChange = useCallback(
		(value: Header.Value) => {
			if (value.searchType !== headerValue.searchType) {
				// eslint-disable-next-line no-param-reassign
				value = assign(clone(value), { search: "" });
			}

			setHeaderValue(value);

			if (value.search !== headerValueSearch) {
				setObject(null);
				executorsRef.current?.unfocus();
			}
		},
		[headerValue.searchType, headerValueSearch, setHeaderValue],
	);

	const mapOnReady = useCallback(() => {
		routeRef.current?.focus();
	}, []);

	const controlButtonsOnCenter = useCallback(() => {
		const executors = executorsRef.current;

		if (executors && executors.focus()) {
			return;
		}

		const mapObject = mapObjectRef.current;

		if (mapObject && mapObject.focus()) {
			return;
		}

		const route = routeRef.current;

		if (!route) return;

		route.focus();
	}, []);

	const controlButtonsOnZoomIn = useCallback(() => {
		if (!mapRef.current) {
			return;
		}

		mapRef.current.zoomIn();
	}, []);

	const controlButtonsOnZoomOut = useCallback(() => {
		if (!mapRef.current) {
			return;
		}

		mapRef.current.zoomOut();
	}, []);

	useLayoutEffect(() => {
		const route = routeRef.current;

		if (!route) return;

		route.focusPoint(activePoint);
	}, [activePoint]);

	const headerOnSelectSearchOptions = (
		option: Header.SearchOption<
			MapService.Search.Object | ExecutorLocations.Model
		>,
	): void => {
		if (isMapObject(option.value)) {
			setObject(option.value);
		} else {
			executorsRef.current?.focusExecutor(option.value.executor.id);
		}

		setHeaderValueSearch(option.label);
	};

	useEffect(() => {
		if (
			"id" in activeOrder ||
			!currentTaxiService?.coordinates ||
			mapCenter
		)
			return;

		setMapWidgetCenter(currentTaxiService?.coordinates);
	}, [
		activeOrder,
		currentTaxiService?.coordinates,
		currentTaxiService?.coordinates.lat,
		currentTaxiService?.coordinates.lng,
		setMapWidgetCenter,
		mapCenter,
	]);

	return (
		<Root sizes="auto! 1fr" maxedWidth maxedHeight>
			<Header
				value={headerValue}
				searchOptions={searchOptions}
				defaultFiltersValue={mapWidgetDefaultExecutorsFilters}
				onChange={headerOnChange}
				onSelectSearchOption={headerOnSelectSearchOptions}
			/>
			<MapBase
				ref={mapRef}
				center={mapCenter ?? defaultMapCenter}
				zoomControl={false}
				zoom={mapZoom}
				maxZoom={19}
				onReady={mapOnReady}
				onChangeCenter={setMapWidgetCenter}
				onChangeZoom={setMapWidgetZoom}
			>
				<Control prepend position="topright">
					<ShowButtons value={showValue} onChange={setShowValue} />
				</Control>
				<Control prepend position="bottomright">
					<ControlButtons
						onCenter={controlButtonsOnCenter}
						onZoomIn={controlButtonsOnZoomIn}
						onZoomOut={controlButtonsOnZoomOut}
					/>
				</Control>

				<MapObject ref={mapObjectRef} object={object} />
				{showValue.route && !object && <Route ref={routeRef} />}
				{showValue.priceZones && <PriceZones />}
				{showValue.sectors && <Sectors />}
				{showValue.parkings && <Parkings />}
				{showValue.executors && (
					<MapExecutorLocations
						ref={executorsRef}
						executorLocations={executorLocationsData.models}
					/>
				)}
			</MapBase>
		</Root>
	);
};

declare namespace Map {
	interface Props {}
}

export default Map;
